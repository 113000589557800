import React, { useContext, useEffect, useState } from "react";
import { AuthState } from "../services/Context";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

function AdminBlock() {
  const { siteState, setSiteState } = useContext(AuthState);
  const [siteStateCheck, setSiteStateCheck] = useState(siteState);
  const token = localStorage.getItem("login_token");
  console.log(siteState);

  useEffect(() => {
    AuthService.siteStatus()
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        if (data[0].registration_active === 1) {
          setSiteStateCheck(true);
        }
      });
  }, [siteState]);

  const renderText = () => {
    <div className="admin-wrapper">
      {siteStateCheck ? (
        <>
          <div>Registracija siuo metu ijungta</div>
          <div>
            <button onClick={() => handleToggleReg(false)}>
              Isjungti registracija
            </button>
          </div>
        </>
      ) : (
        <>
          <div>Registracija siuo metu isjungta</div>
          <div>
            <button onClick={() => handleToggleReg(true)}>
              Ijungti registracija
            </button>
          </div>
        </>
      )}
    </div>;
  };
  function handleToggleReg(data) {
    const changeStatus = { status: data };

    UserService.toggleReg(token, changeStatus)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data.message === "changing registration state...") {
          alert("Registracijos statusas pakeistas");

          setSiteState((current) => !current);
        } else {
          alert("Įvyko klaida, bandykite dar kartą.");
        }
      });
  }

  return (
  <>{renderText}</>
  )
}

export default AdminBlock;
