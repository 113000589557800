import React, { useContext, useEffect, useState } from "react";
import { AuthState } from "../services/Context";
import UserService from "../services/user.service";
import { googleUrl } from "../services/utils";

function Birthday() {
  const token = localStorage.getItem("login_token");
  const { userLoginState } = useContext(AuthState);
  const [giftData, setGiftData] = useState([]);
  const [reserved, setReserved] = useState(false);
  const [filterGiftData, setFilterGiftData] = useState([giftData]);

  useEffect(() => {
    if (userLoginState) {
      UserService.getGifts(token)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          let sortedProducts = data.sort(
            (p2, p1) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);

          setGiftData(sortedProducts);
          setFilterGiftData(sortedProducts);
        });
    }
  }, [token, userLoginState, reserved]);

  const giftType = [...new Set(giftData.map(item => item.type))];
  

  const filterGifts = (type) => {
    const typeSQL = { name: type };
    UserService.getFilteredGifts(token, typeSQL)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        let sortedProducts = data.results[0].sort(
          (p2, p1) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);
        setFilterGiftData(sortedProducts);
      });
  };

//   const sortedGifts = () => {
//     let sortedProducts = filterGiftData.sort(
//       (p2, p1) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);
//       setFilterGiftData(sortedProducts);
// console.log(sortedProducts)
//   };

  const reserveGift = (giftData) => {
    if (window.confirm("Ar tikrai norite rezervuoti šią dovaną?") === true) {
      const reservationData = {
        gift_id: giftData.gift_id,
        gift_name: giftData.gift_name,
        gift_url: giftData.gift_url,
        gift_pic: giftData.gift_pic,
        price: giftData.price,
      };

      UserService.giftReservation(token, reservationData)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          if (data.message === "gift reserved") {
            alert("Dovana sėkmingai rezervuota.");

            setReserved((current) => !current);
          } else {
            alert("Įvyko klaida, bandykite dar kartą.");
          }
        });
    }
  };

  return (
    <>
      {userLoginState ? (
        <div className="birthday-wrapper">
          <div className="buttons-wrapp">
            {giftType.map((type, index)=>(
              <div className="filter-gift">
              <button key={index} onClick={() => filterGifts(type)}>{type}</button>
            </div>
            ))}
            {/* <div className="filter-gift">
              <button onClick={() => filterGifts("Lego")}>Lego</button>
            </div>
            <div className="filter-gift">
              <button onClick={() => filterGifts("Kortos")}>Kortos</button>
            </div>
            <div className="filter-gift">
              <button onClick={() => filterGifts("Knyga")}>Knygos</button>
            </div>
            <div className="filter-gift">
              <button onClick={() => filterGifts("Galvosukis")}>
                Galvosūkiai
              </button>
            </div> */}
            {/* <div className="filter-gift">
              <button onClick={() => filterGifts("Nintendo")}>Nintendo</button>
            </div> */}
            <div className="filter-gift">
              <button onClick={() => filterGifts("*")}>Visos dovanos</button>
            </div>
            {/* <div className="sort-gift">
              <button onClick={() => sortedGifts()}>Nuo pigiausios</button>
            </div> */}
          </div>
          <div className="gifts-wrapper">
            <div className="wrapper-title">Dovanų sarašas</div>
            <div className="gifts-container-main">
              {filterGiftData.map((gift) => (
                <div className="single-gift-container" key={gift.id}>
                  <div className="gift-image-wrap">
                    <img
                      className="gift-image"
                      src={gift.gift_pic}
                      alt="gift logo"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </div>
                  <div className="gift-info-wrap">
                    <div className="single-gift-name">{gift.type}</div>
                    <div className="single-gift-name-value">
                      {gift.gift_name}
                    </div>
                    <div className="price-wrapper">
                      <div className="single-gift-price">Kaina:</div>
                      <div className="single-gift-price-value">
                        ~ {gift.price} EUR
                      </div>
                    </div>
                    <div className="single-gift-status">Dovanos statusas:</div>
                    <div
                      className={gift.reserved ? "gift-reserved" : "gift-free"}
                    >
                      {gift.reserved ? "Rezervuota" : "Laisva"}
                    </div>
                    <div className={`rating-${gift.rating}`}>Reitingas</div>
                  </div>
                  <div className="buttons-wrapp">
                    {/* <div className="single-gift-url">
                      <button onClick={() => openUrl(gift.gift_url)}>
                        Nuoroda
                      </button>
                    </div> */}
                    <div className="single-gift-search">
                      <button onClick={() => googleUrl(gift.gift_name)}>
                        Ieškoti
                      </button>
                    </div>
                    <div className="single-gift-commit">
                      {gift.reserved ? (
                        ""
                      ) : (
                        <button
                          onClick={() =>
                            reserveGift({
                              gift_id: gift.id,
                              gift_name: gift.gift_name,
                              gift_url: gift.gift_url,
                              gift_pic: gift.gift_pic,
                              price: gift.price,
                            })
                          }
                        >
                          Rezervuoti
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <div className="single-gift-delete">
                    <button  id={index} onClick={() => deleteGift(gift.id, gift.gift_description)}>
                      Delete gift
                    </button>
                  </div> */}

                  <div></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="error">
          <h1>Informacija prieinama tik prisijungusiems vartotojams!</h1>
        </div>
      )}
    </>
  );
}
export default Birthday;
