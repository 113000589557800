import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Error from "./services/Error";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import { AuthState } from "./services/Context";
import Register from "./components/Register";
import Birthday from "./components/Birthday";
import UserProfile from "./components/UserProfile";
import Cult from "./components/Cult";
import AuthService from "./services/auth.service";

function App() {
  const [userLoginState, setUserLoginState] = useState(false);
  const [submitState, setSubmitState] = useState(false);
  const [siteState, setSiteState] = useState(false);
  const [userRole, setUserRole] = useState();

  const token = localStorage.getItem("login_token");

  useEffect(() => {

    if (!token) {
      setUserLoginState(false);
    } else {
      setUserLoginState(true);
    }
    AuthService.siteStatus()
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      console.log(data);
      if(data[0].registration_active === 1){
        setSiteState(true)
      }
      
    });
    

  }, [siteState, token]);
  console.log(userRole)

  return (
    <div className="App">
      <div>
        <main>
          <AuthState.Provider
            value={{
              userLoginState,
              setUserLoginState,
              submitState,
              setSubmitState,
              siteState,
              userRole,
              setUserRole
            }}
          >
            <Header />
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/birthday" element={<Birthday />} />
              <Route path="/cult" element={<Cult />} />
              <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
          </AuthState.Provider>
        </main>
      </div>
    </div>
  );
}

export default App;
