import React, { useState, useEffect, useContext } from "react";
import { googleUrl } from "../services/utils";
import { AuthState } from "../services/Context";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import AdminBlock from "./AdminBlock";

function UserProfile() {
  const [giftData, setGiftData] = useState([]);
  const dataToken = JSON.parse(localStorage.getItem("data_token"));
  const { userLoginState, userRole } = useContext(AuthState);
  const userName = dataToken.name;
  const [reserved, setReserved] = useState(false);

  console.log(userRole);
  useEffect(() => {
    const token = localStorage.getItem("login_token");

    if (userLoginState) {
      AuthService.profile(token)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setGiftData(data);
        });
        
    }
  }, [userLoginState, reserved]);

  const cancelReserve = (data) => {
    if (window.confirm("Ar tikrai norite panaikinti rezervaciją?") === true) {
      const reservationData = {
        res_id: data.resId,
        gift_id: data.giftId,
      };
      const token = localStorage.getItem("login_token");
      UserService.cancelReservation(token, reservationData)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          if (data.message === "res deleted") {
            alert("Rezervacija sėkmingai panaikinta.");

            setReserved((current) => !current);
          }
          
        });
    }
  };

 

  return (
    <>
      {userLoginState ? (
             
        <div className="user-wrapper">
          <div className="wrapper-title">
            <h1>Jūsų profilis</h1>
          </div>
          {userRole === 1 ? (<div><AdminBlock/></div>):(<div></div>) }
          
          <div className="user-info">
            <h1>Sveiki {userName},</h1>
            <div className="gifts-wrapper">
              
              <div className="gifts-container">
                {giftData.length === 0 ? (
                  <div>kolkas nieko nesate rezervavę</div>
                ) : (<>
                <div className="reserve-title">Jūsų rezervacijos:</div>
                <div className="gifts-container-main">
                  {giftData.map((gift) => (
                    <div className="single-gift-container" key={gift.gift_id}>
                      <div className="gift-image-wrap">
                        <img
                          className="gift-image"
                          src={gift.gift_pic}
                          alt="gift logo"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      </div>
                      <div className="gift-info-wrap">
                        <div className="single-gift-name-value">
                          {gift.gift_name}
                        </div>
                        <div className="price-wrapper">
                          <div className="single-gift-price">Kaina:</div>
                          <div className="single-gift-price-value">
                            {gift.price} EUR
                          </div>
                        </div>
                        <div className="single-gift-status">
                          Dovanos statusas:
                        </div>
                        <div
                          className={
                            gift.reserved ? "gift-reserved" : "gift-free"
                          }
                        >
                          {gift.reserved ? "Rezervuota" : "Laisva"}
                        </div>
                      </div>
                      <div className="buttons-wrapp">
                        {/* <div className="single-gift-url">
                          <button onClick={() => openUrl(gift.gift_url)}>
                            Nuoroda
                          </button>
                        </div> */}
                        <div className="single-gift-search">
                      <button onClick={() => googleUrl(gift.gift_name)}>
                        Ieškoti
                      </button>
                    </div>
                        <div className="single-gift-cancel-reservation">
                          <button
                            onClick={() =>
                              cancelReserve({
                                resId: gift.id,
                                giftId: gift.gift_id,
                              })
                            }
                          >
                            Panaikinti rezervaciją
                          </button>
                        </div>
                      </div>

                      <div></div>
                    </div>
                 ))}</div></> 
                )}
              
              </div>
            </div>
          </div>
        </div>
    
      ) : (
        <div className="error">
          <h1>Informacija prieinama tik prisijungusiems vartotojams!</h1>
        </div>
      )}
    </>
  );
}

export default UserProfile;
