import React, { useContext, useEffect, useState } from "react";
import UserService from "../services/user.service";
import { AuthState } from "../services/Context";

function Cult() {
  const { userLoginState } = useContext(AuthState);
//   const dataToken = JSON.parse(localStorage.getItem("data_token"));
  const token = localStorage.getItem("login_token");
  const [siteData, setSiteData] = useState([]);
  // const userName = dataToken.name;

  useEffect(() => {
    if (userLoginState) {
      UserService.getSite(token)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          setSiteData(data);
        });
    }
  }, [token, userLoginState]);

  return (
    <>
      <div className="home-wrapper">Čia yra Beno puslapis</div>

      {userLoginState ? (
        <div className="youtube-wrapper">
          <div className="clips-container">
            {siteData.length === 0 ? (
              <div>kolkas nieko nėra</div>
            ) : (
                <>
                <div>Cult of the lamb game play:</div>
              {siteData.map((clip) => (
                <div className="single-video-container" key={clip.id}>
                  <div className="video-info-wrap">
                    <iframe
                      className="video"
                      title="Youtube player"
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://youtube.com/embed/${clip.youtube_link}?autoplay=0`}
                    ></iframe>
                  </div>
                </div>
              ))}</>
            )}
          </div>
        </div>
      ) : (
        <div className="error">
          <h1>Informacija prieinama tik prisijungusiems vartotojams!</h1>
        </div>
      )}
    </>
  );
  // <div className="home-wrapper">
  //   <div className="wrapper-title">
  //     <h1>Plan for 2023</h1>
  //   </div>
  //   <div>
  //     {userLoginState ? (
  //       <>
  //         <div>
  //           <b>{dataToken.name}</b>, welcome back.
  //           {/* <Link to="/profile">profile</Link> */}
  //         </div>
  //       </>
  //     ) : (
  //       <div>
  //         <>Only for authorized users. Please </>
  //         <Link to="/login">login</Link>.
  //       </div>
  //     )}
  //   </div>
  // </div>
}

export default Cult;
