// const AUTH_URL = "https://tasks2023-8rev.onrender.com/v1/auth/";
// const TASKS_URL = "https://tasks2023-8rev.onrender.com/v1/tasks/";
const AUTH_URL_LOCAL = "http://localhost:48888/nodeapp/v1/auth/";
const GLOBAL_URL_LOCAL = "http://localhost:48888/nodeapp/v1/site/";
const GIFTS_URL_LOCAL = "http://localhost:48888/nodeapp/v1/gifts/";
const SITE_URL_LOCAL = "http://localhost:48888/nodeapp/v1/site/";
const AUTH_URL_REMOTE = "https://gimtadienis.gpl.lt/nodeapp/v1/auth/";
const GLOBAL_URL_REMOTE = "https://gimtadienis.gpl.lt/nodeapp/v1/site/";
const GIFTS_URL_REMOTE = "https://gimtadienis.gpl.lt/nodeapp/v1/gifts/";
const SITE_URL_REMOTE = "https://gimtadienis.gpl.lt/nodeapp/v1/site/";

const siteLocalization = 'global';


  if (siteLocalization === 'local'){
    var AUTH_URL = AUTH_URL_LOCAL;
    var GLOBAL_URL = GLOBAL_URL_LOCAL;
    var GIFTS_URL = GIFTS_URL_LOCAL;
    var SITE_URL = SITE_URL_LOCAL;

  }else{
    AUTH_URL = AUTH_URL_REMOTE;
    GLOBAL_URL = GLOBAL_URL_REMOTE;
    GIFTS_URL = GIFTS_URL_REMOTE;
    SITE_URL = SITE_URL_REMOTE;
  }
  export {AUTH_URL, GLOBAL_URL, GIFTS_URL, SITE_URL}


export function openUrl (url) {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  export function googleUrl (url) {
    window.open(`https://www.google.com/search?q=${url}+kaina`, "_blank", "noopener,noreferrer");
  };

