import { GIFTS_URL, SITE_URL, AUTH_URL } from "./utils";

const getGifts = (token) => {
  return fetch(GIFTS_URL + "gifts", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const getFilteredGifts = (token, data) => {
  return fetch(GIFTS_URL + "filteredgifts", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const giftAdd = (token, data) => {
  return fetch(GIFTS_URL + "addtask", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const giftReservation = (token, data) => {
  return fetch(GIFTS_URL + "reservegift", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const cancelReservation = (token, data) => {
  return fetch(GIFTS_URL + "unreservegift", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const giftDelete = (token, data) => {
  return fetch(GIFTS_URL + "delete", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const getSite = (token) => {
  return fetch(SITE_URL + "youtube", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const toggleReg = (token, data) => {
  return fetch(AUTH_URL + "setreg", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const UserService = {
  getGifts,
  getFilteredGifts,
  giftAdd,
  giftDelete,
  giftReservation,
  cancelReservation,
  getSite,
  toggleReg
};

export default UserService;
