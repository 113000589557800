import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthState } from "../services/Context";

function Navbar() {
  const { userLoginState, setUserLoginState, siteState } = useContext(AuthState);

  const handleLogout = () => {
    localStorage.clear();
    setUserLoginState(false);
  };

  return (
    <>
      {userLoginState ? (
        <div className="navigation">
          <div className="nav-links">
            <Link className="nav-home" to="/">
              Pradžia
            </Link>
          </div>
          {/* <div className="nav-links">
            <Link className="nav-tasks" to="/cult">
              Kultas
            </Link>
          </div> */}
          <div className="nav-links">
            <Link className="nav-home" to="/birthday">
              Dovanos
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-profile" to="/profile">
              Profilis
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav-logout" to="/">
              <button className="logout-btn" onClick={handleLogout}>
                Atsijungti
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="navigation">
          <div className="nav-links">
            <Link className="nav-home" to="/">
              Pradžia
            </Link>
          </div>
          {/* <div className="nav-links">
            <Link className="nav-tasks" to="/cult">
              Kultas
            </Link>
          </div> */}
          
          <div className="nav-links">
            <Link className="nav-home" to="/birthday">
              Dovanos
            </Link>
          </div>
          
          {siteState ? (<><div className="nav-links">
            <Link className="nav-register" to="/register">
              Užsiregistruoti
            </Link>
          </div>
          </>):(<></>)}
          <div className="nav-links">
            <Link className="nav-login" to="/login">
              Prisijungti
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
