import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Loading from "./Loading";

function Register() {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordCheck, setUserPasswordCheck] = useState("");
  const [loading, setLoading] = useState();
  const [gotResponse, setGotResponse] = useState();

  const handleChangeName = (e) => {
    setUserName(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setUserEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setUserPassword(e.target.value);
  };

  const handleChangePasswordCheck = (e) => {
    setUserPasswordCheck(e.target.value);
  };

  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    const newUser = {
      name: userName,
      email: userEmail,
      password: userPassword,
    };
    document.getElementById("registration-form").reset();

    if (!userName || !userEmail || !userPassword || !userPasswordCheck) {
      alert("Užpildykite visus laukelius");
      return;
    } else if (userPassword !== userPasswordCheck) {
      alert("Slaptažodžiai nesutampa, bandykite dar kartą.");
      return;
    } else {
      setLoading(true);
      AuthService.signUp(newUser)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          console.log(data)
          if (data.error === "email already exists") {
            alert("Toks el.paštas jau egzistuoja.");
            setLoading(false);
          } else if (data === 'User registered') {
            alert("Registracija sėkminga.");
            setGotResponse("registered");
          }

          
        });
    }
    document.getElementById("registration-form").reset();
    // navigate("/login", { replace: true }
  }
  useEffect(() => {
    if (gotResponse) {
      setLoading(false);

      navigate("/login", { replace: true });
    } else {
      <div>Ivyko klaida, bandykite dar kartą.</div>;
    }
  }, [gotResponse, navigate]);

  const checkIfSuccess = loading ? (
    <>
      <div>siunčiami duomenys....</div>
      <Loading />
    </>
  ) : (
    <div></div>
  );

  return (
    <>
      <div className="register-wrapper">
        <div>{checkIfSuccess}</div>

        <div className="new-user-registration-title">Naujas vartotojas</div>
        <div className="message-text"></div>
        <div className="add-user">
          <form id="registration-form">
            <label htmlFor="user-name">
              Vardas<span className="red">*</span>
            </label>
            <input
              value={userName}
              type="text"
              id="user-name"
              name="user-name"
              onChange={handleChangeName}
            />

            {/* <label htmlFor="user-last-name">
            Last name<span className="red">*</span>
          </label>
          <input
            value={userLastName}
            type="text"
            id="user-last-name"
            name="user-last-name"
            onChange={handleChangeLastName}
          />

          <label htmlFor="user-login-name">
            Login name<span className="red">*</span>
          </label>
          <input
            value={userLoginName}
            type="text"
            id="user-login-name"
            name="user-login-name"
            onChange={handleChangeLoginName}
          /> */}

            <label htmlFor="user-email">
              El. paštas<span className="red">*</span>
            </label>
            <input
              value={userEmail}
              type="email"
              id="user-email"
              name="user-email"
              onChange={handleChangeEmail}
              formNoValidate
            />

            <label htmlFor="user-password">
              Slaptažodis<span className="red">*</span>
            </label>
            <input
              value={userPassword}
              type="password"
              id="user-password"
              name="user-password"
              onChange={handleChangePassword}
            />
            <label htmlFor="user-password-check">
              Pakartoti slaptažodį<span className="red">*</span>
            </label>
            <input
              value={userPasswordCheck}
              type="password"
              id="user-password-check"
              name="user-password-check"
              onChange={handleChangePasswordCheck}
            />
            <div className="required">* būtini laukai</div>
            <button
              className="form-submit-btn"
              onClick={handleSubmit}
              type="submit"
            >
              Siųsti
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Register;
