import React, { useContext } from "react";
// import Loading from "./Loading";
// import { Link } from "react-router-dom";
// import UserService from "../services/user.service";
import { AuthState } from "../services/Context";

function Home() {
  const { userLoginState, siteState } = useContext(AuthState);
  
  return (
    <>
      <div className="home-wrapper">
        <div>Čia yra Beno puslapis</div>
        
        {siteState ? (userLoginState ? (<div>Sveiki atvykę</div>):(<div>Prašome užsiregistruoti arba prisijungti.</div>)):(<><div>Puslapis skirtas tik registruotiems vartotojams.</div><div>Registracija šiuo metu išjungta.</div></>)}
         </div>
    </>
  );
}

export default Home;
