import { GLOBAL_URL, AUTH_URL } from "./utils";

const siteStatus = () => {
  return fetch(GLOBAL_URL + "status", {});
};

const setToken = (token, dataToken) => {
  localStorage.setItem("login_token", token);
  localStorage.setItem("data_token", JSON.stringify(dataToken));
};

const signUp = (data) => {
  return fetch(AUTH_URL + "register", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const signIn = (data) => {
  return fetch(AUTH_URL + "login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const profile = (token) => {
  return fetch(AUTH_URL + "profile", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const AuthService = {
  setToken,
  signUp,
  signIn,
  profile,
  siteStatus,
};

export default AuthService;
