import React from "react";
// import cultHeader from '../media/cult.webp'

function Header() {
  return (
    <div className="header-wrapper">
      {/* <img className="header-logo" src={cultHeader} alt="cult" /> */}
      {/* <div className="site-tite">Beno puslapis</div> */}
    </div>
  );
}

export default Header;
